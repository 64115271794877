const checkpoint = 300;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= checkpoint) {
    opacity = 1 - currentScroll / checkpoint;
  } else {
    opacity = 0;
  }
  document.querySelector(".scroll-indicator").style.opacity = opacity;
});

$(document).ready(function() {
  $('a[href^="#"]').on('click', function(event) {
    console.log(event);
    window.e = event;

    event.preventDefault();
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 350, "linear", function () {
      window.location.hash = href;
    })

  });
});
